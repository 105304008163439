.card_container {
display: flex;
justify-content: center;
align-items: center;
gap: 8rem;

}
.card_box {
    width: 18rem;
    margin-top: 3rem;
}
.card img {
    padding: 6px 0px;
    transition: transform 0.2s ease;
    cursor: pointer;
}
.card:hover img {
    transform: scale(1.2);
}

@media (max-width: 768px) {
    .card_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        
        }
}