@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Platypi:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
    text-align: center;
    padding: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin: 20px auto;
  }
  
  label {
    margin-bottom: 8px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea,
  button {
    padding: 8px;
    margin-bottom: 12px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  
@media screen and (max-width: 350px) {
    form {
      width: 100%;
    }
    
}

  @media screen and (min-width: 420px) and (max-width: 600px) {
    heading {
       padding: 23px;
       font-family: "Kalam", cursive;
       font-weight: 700;
       text-decoration: underline;
    }
}
/* @media screen and (min-width: 350px) and (max-width: 370px) { */

  @media screen and (min-width: 340px) and (max-width: 370px) {
    form {
      padding: 0 20px !important;
      width: 306px !important;
  }
    .heading {
      padding: 8px !important;
      text-decoration: underline;
      font-family: "Kalam", cursive;
  }
  
  }

  @media screen and (min-width: 360px) and (max-width: 420px) {
  form {
    padding: 0 20px !important;
    width: 321px !important;
}
  .heading {
    font-family: "Kalam", cursive;
    padding: 8px !important;
    text-decoration: underline;
}


@media screen and (min-width: 550px) and (max-width: 750px) {

}

}