.whatsapp-icon {
    position: fixed;       /* Positioning type */
    bottom: 20px;             /* Distance from the bottom of the viewport */
    right: 20px;              /* Distance from the right of the viewport */
    padding: 10px;            /* Padding around the image */
    z-index: 9999;            /* Ensures the icon stays above other elements */
    cursor: pointer;          /* Cursor style */
}

.whatsapp-icon img {
    width: 83px;
}

/* Optional: Hover effect */
.whatsapp-icon:hover {
    opacity: 0.8;             /* Reduce opacity on hover for visual feedback */
}


@media screen and (max-width: 349px) {
    .whatsapp-icon img {
        width: 50px;
    }
    .whatsapp-icon {
        right: 17px;
    }
}

@media screen and (min-width: 350px) and (max-width: 550px) {
  
.whatsapp-icon img {
    width: 64px;
}
}

@media screen and (min-width: 550px) and (max-width: 750px) {
    .whatsapp-icon img {
        width: 74px;
}
}
