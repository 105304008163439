
.titles {
    /* width: 100%; */
    background-color: #333399;
    padding: 14px;
    display: flex;
    font-size: 21px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    justify-content: center;
    color: white;
}
.details {
    padding: 30px;
    font-size: 15px;
    line-height: 48px;
}

/* Flip Css */
.flip-container {
    /* perspective: 1000px; */
    width: 300px;
    height: 300px;
  }
  .boxes {
    padding: 20px;
    gap: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .flip-container:hover .flipper,
  .flip-container.hover .flipper {
    transform: rotateY(180deg);
  }
  
  .flipper {
    width: 100%;
    height: 100%;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
  }
  
  .front,
  .back {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
  }
  
  .front {
    z-index: 2;
    
  }
  
  .front img {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .back {
    border-radius: 20px;
    transform: rotateY(180deg);
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    font-size: 15px;
    color: #333;
    padding: 10px;
    text-align: center;
    line-height: 22px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  
@media screen and (min-width: 320px) and (max-width: 550px) {
  .details {
    padding: 10px 30px !important;
    line-height: 2;
    text-align: justify;
  }
  .front {
    width: 100%;
    z-index: 2;
}

}

