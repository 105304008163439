@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Platypi:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');* {
    margin: 0;
    padding: 0;
}
.wrapper {
    
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust the z-index as needed */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 173px;
    background-color: white;
    box-shadow: 0px 3px 4px #c0bbb8;
}
.cnt_dtl {
    padding: 11px 138px;
    background-color: #333399;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mail {
    display: flex;
    align-items: center;
    color: white;
}
.mail-icon{
    padding: 0px 13px;
    height: 25px;
    width: 25px;
}
.phn-icon {
    padding: 0px 7px;
    height: 23px;
    width: 34px;
}
.greet {
    line-height: 1.3;
    color: yellow;
    font-size: 17px;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
}
.phn {
    display: flex;
    align-items: center;
    color: white;
}
.logo img {
    padding: 7px;
    width: 90px;
    cursor: pointer;
}
.list ul {
    display: flex;
    gap: 3.5rem;
    align-items: center;
}
.list ul li {
    list-style-type: none;
    font-size: 18px;
    font-weight: 650;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.list ul li a {
    text-decoration: none;
    color: #333399;
}
.menuIcon {
    display: none;
}
.heading {
    font-family: "Kalam", cursive;
       font-weight: 700;
    color: #762f53;
    font-weight: 600;
    font-size: 2rem;
    padding: 30px;
    cursor: pointer;
    text-align: center;
}

@media screen and (max-width: 349px) {
    .logo img {
        padding: 7px;
        width: 61px;
        cursor: pointer;
    }
    .wrapper {
        padding: 0 81px;
    }
    .button {
        padding: 29px;
    }
    .cnt_dtl {
        text-align: center;
        align-items: center;
        padding: 2px 23px;
       }
    .all_img img {
        width: 270px !important;
    }
    .all_img {
        gap: 2.5rem !important;
    }
    .cnt_dtl {
        flex-direction: column;
        display: flex;
    }
    .menuIcon {
        display: block;
    }
    .heading h2 {
        padding: 0px !important;
    }
    .menu {
        
        cursor: pointer;
        position: fixed;
        display: none;
            background-color: white;
            list-style: none;
            gap: 2rem;
            font-weight: 500;
            flex-direction: column;
            right: 0;
            top: 6rem;
            width: 240px;
            z-index: 2;
            min-width: 8rem;
            padding: 3rem;
            display: flex;
            border-radius: 1rem;
            transition: all 300ms ease;
            align-items: flex-start;
            
            justify-content: flex-end;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
        
        .heading {
            padding: 1px 27px !important;
        }
        .paragraph {
            padding: 9px 29px !important;
            text-align: center;
        }
        
}

@media screen and (min-width: 340px) and (max-width: 550px) {
    .wrapper {
        padding: 0 81px;
    }
    .button {
        padding: 29px;
    }
    .cnt_dtl {
        text-align: center;
        align-items: center;
        padding: 2px 23px;
       }
    .all_img img {
        width: 315px !important;
    }
    .all_img {
        gap: 2.5rem !important;
    }
    .cnt_dtl {
        flex-direction: column;
        display: flex;
    }
    .menuIcon {
        display: block;
    }
    .heading h2 {
        padding: 0px !important;
    }
    .menu {
        
        cursor: pointer;
        position: fixed;
        display: none;
            background-color: white;
            list-style: none;
            gap: 2rem;
            font-weight: 500;
            flex-direction: column;
            right: 0;
            top: 6rem;
            width: 240px;
            z-index: 2;
            min-width: 8rem;
            padding: 3rem;
            display: flex;
            border-radius: 1rem;
            transition: all 300ms ease;
            align-items: flex-start;
            
            justify-content: flex-end;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
        
        .heading {
            padding: 1px 27px !important;
        }
        .paragraph {
            padding: 9px 29px !important;
            text-align: center;
        }
        
}



   @media screen and (min-width: 550px) and (max-width: 750px) {
    .menuIcon {
        display: block;
    }
    .cnt_dtl {
        flex-direction: column !important;
        display: flex !important;
        align-items: center;
        padding: 9px;
        text-align: center;
    }
    .menu {
        text-transform: uppercase;
        font-size: .9rem;
        list-style: none;
        cursor: pointer;
        position: fixed;
        display: none;
            background-color: white;
            list-style: none;
            gap: 2rem;
            font-weight: 500;
            flex-direction: column;
            right: 0;
            top: 7rem;
            width: 10%;
            z-index: 2;
            min-width: 9rem;
            padding: 2rem;
            display: flex;
            border-radius: 2rem;
            transition: all 300ms ease;
            align-items: flex-start;
            padding: 3rem;
            justify-content: flex-end;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
    }

    @media screen and (min-width: 600px) and (max-width: 800px) {
        .menuIcon {
            display: block;
        }
    
        .menu {
            text-transform: uppercase;
            font-size: .9rem;
            list-style: none;
            cursor: pointer;
            position: fixed;
            display: none;
                background-color: white;
                list-style: none;
                gap: 2rem;
                font-weight: 500;
                flex-direction: column;
                right: 0;
                top: 7rem;
                width: 10%;
                z-index: 2;
                min-width: 9rem;
                padding: 2rem;
                display: flex;
                border-radius: 2rem;
                transition: all 300ms ease;
                align-items: flex-start;
                padding: 3rem;
                justify-content: flex-end;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            }
        }

    

    
        
       

