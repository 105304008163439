@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Platypi:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    .details_ {
    font-family: "Poppins", sans-serif;;
    padding: 40px 0px;
    line-height: 27px;
}
.decorate {
    
.button1 {
    background-color: rgb(148, 228, 255);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.button1:hover {
    background-color: rgb(15, 155, 202);
}
.conatiner {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}
.conatiner {
    max-width: 100%;
    padding: 40px 113px;
}
.left_part {
    padding: 20px;
}
.iimage {
    width: 170%;
}
.iimage img {
    width: 100%;
    border-radius: 20px;
}
.details_ {
    padding: 0px 20px;
    line-height: 40px;
}


@media screen and (max-width: 300px) {
    .conatiner {
        flex-direction: column;
        max-width: 100%;
        padding: 30px 19px;
        gap: 0.7rem;
    }
    .button1 {
        background-color: rgb(148, 228, 255);
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        text-decoration: none !important;
        display: inline-block;
        font-size: 16px;
    }
    
    
    .button1:hover {
        background-color: rgb(15, 155, 202);
    }
    .iimage {
        width: 100%;
    }
}

@media screen and (min-width: 300px) and (max-width: 550px) {
    .conatiner {
        flex-direction: column ;
        padding: 20px 18px;
        text-align: justify;
        align-items: center;
        gap: 1rem;
    }
    .tital {
        text-align: left;
    }
    .details_ {
        padding: 13px 0px;
        line-height: 26px;
    }
    .button1 {
        background-color: rgb(148, 228, 255);
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        text-decoration: none !important;
        display: inline-block;
        font-size: 16px;
    }
    
    .button1:hover {
        background-color: rgb(15, 155, 202);
    }
    .iimage {
        width: 100%;
    }
       

}


@media screen and (min-width: 550px) and (max-width: 750px) {
    .iimage {
        width: 100%;
    }
    .conatiner {
        flex-direction: column !important;
        padding: 20px 18px !important;
        text-align: justify !important;
        align-items: center !important;
        gap: 1rem !important;
    }
    .button1 {
        display: flex !important;
        color: white;
        text-decoration: none;
    }
}

@media screen and (min-width: 750px) and (max-width: 850px) {
  
    .product_img img {
        width: 100% !important;
    }
    .iimage {
        width: 100% !important;
    }
    .conatiner {
        flex-direction: column !important;
        padding: 20px 18px !important;
        text-align: justify !important;
        align-items: center !important;
        gap: 1rem !important;
    }
    .button1 {
        display: flex !important;
        text-decoration: none;
        color: white;
    }
}


}


@media (min-width:400px)
{
   .iimage > img{
    width: 100%;
    height: 500px;
} 
.tital h1 {
    padding: 10px 15px;
    text-align: center;
    font-family: "Kalam", cursive;
    color: #762f53;
}
.details_ {
    padding: 0px 33px;
    text-align: justify;
    color: blue;
}

.button1 {
    margin: 1rem !important;
    padding: 10px !important;
    background-color: skyblue !important;
    color: white !important;
    /* padding: 10px 20px !important; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center !important;
    text-decoration: none !important;
    display: flex;
    font-size: 16px;
}

.button1:hover {
    background-color: deepskyblue;
}
}
.conatiner {
    /* flex-direction: column !important; */
    padding: 20px 18px !important;
    text-align: justify !important;
    align-items: center !important;
    gap: 1rem !important;
    border-radius: 20px;
}
.conatiner img {
    border-radius: 20px;
}
.button1 a{
    text-decoration: none !important; 
}
.button1 {
    font-weight: 800; 
}