
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    background: #f2f2f2;
  }
  
  
  @keyframes slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .logos {
    overflow: hidden;
    padding: 30px 0;
    background: white;
    white-space: nowrap;
    position: relative;
  }
  
  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    width: 250px;
    height: 100%;
    content: "";
    z-index: 2;
  }
  
  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }
  
  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }
  
  .logos:hover .logos-slide {
    animation-play-state: paused;
  }
  
  .logos-slide {
    display: inline-block;
    gap: 2rem;
      display: flex;
    flex-direction: row;
    animation: 35s slide infinite linear;
  }
  
  .logos-slide img {
    width: 172px;
    height: 150px;
    padding: 4px;
    margin: 1px 80px;
  }
  

  .box_wrap {
    border: 1px solid rgb(131, 127, 127);
    width: 200px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }
  .text_name {
    font-size: 14px;
    padding: 7px;
    color: rgb(3, 2, 2);
  }

