@import url('https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.cover {
    background-color: #ffffff;
}
.heading h2{
    
       font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
       font-weight: 700;
    color: #762f53;
    font-weight: 600;
    font-size: 2rem;
    padding: 30px;
    cursor: pointer;
    text-align: center;
    font-family: "Platypi", serif;
}


.paragraph {
    padding: 28px  150px 28px;
    color: #000;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 28px;
    margin: 0;
    /* padding: 0; */
    text-align: center;
}


.button {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.all-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}
.all-img img {
    width: 25%;
}





@media only screen and (max-width: 768px) {
    .all-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
    }
    .all-img img {
        width: 25%;
    }
    
}

@media screen and (min-width: 340px) and (max-width: 550px) {
    
    .paragraph {
        
        padding: 13px 37px !important;
        
    }
    .heading h2 {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;  
        padding: 8px !important;
    }
}

@media screen and (min-width: 550px) and (max-width: 750px) {
    .paragraph {
        
        padding: 13px 37px !important;
        text-align: justify !important;
    }
    .heading h2 {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;  
        padding: 8px !important;
    }
}