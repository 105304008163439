
.product_box {
    width: 20%;
    height: 60%;
    padding: 9px;
    border-radius: 20px;
    border: .1px solid rgb(133, 76, 230);
    box-shadow: #175ce626 0 4px 24px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.product_box:hover {
    border: .1px solid rgb(215, 0, 0);
    background-color: white;
}

.topic img {
    width: 30%;
    
}

.product_img {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.product_img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.product_img img:hover {
    transform: scale(1.1); /* Zoom effect */
    
}
.brand_name {
   
    border: 2px solid red;
    display: flex;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    text-align: center;
    padding: 10px; 
    font-weight: 600;
    font-size: 26px;
    justify-content: center;   
}

.product {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
}
.box_conatainer {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
}


@media screen and (max-width: 349px) {
    .box_conatainer {
        align-items: center;
        flex-direction: column;
    }
    .product_box {
        width: 85%;
    }
}
@media screen and (min-width: 350px) and (max-width: 550px) {
    .heading {
        margin-top: 1.4rem;
    }
    .product_box {
        width: 93% !important;
        padding: 0px !important;
    }
    .box_conatainer {
        padding: 17px !important;
    }
    .product_img {
        width: 94%;
    }
    .product_img img {
        width: 100% !important;
    }
    .box_conatainer { 
        gap: 3rem !important;
    }
    .details {
        text-align: justify;
        padding: 0px 14px;
    }
    .topic img {
        border-radius: 20px;
        padding: 6px;
    }
  }

  
@media screen and (min-width: 550px) and (max-width: 750px) {
    .product_img img {
        width: 100%;
    }
    .box_conatainer {
        gap: 3rem;
    }
    .product_box {
        width: 74%;

    }
    .box_conatainer {
        gap: 3rem;
    }
    
    .paragraph {
        padding: -1px 49px;
        text-align: center;
        color: #1576bb;
    }

    
}


